<template>
	<div class="mhome">
		<div class="mhome-wrap" v-infinite-scroll="handleScroll">
			<div class="mhome-head">
				<div class="item" :class="{active: navActive('all')}" @click="orderChange()">{{ $t('mobile.actions.all') }}</div>
				<div class="item" :class="{active: navActive('payment')}" @click="orderChange(11)">{{ $t('mobile.actions.orderPayment') }}</div>
				<div class="item" :class="{active: navActive('delivery')}" @click="orderChange(20)">{{ $t('mobile.actions.orderDelivery') }}</div>
				<div class="item" :class="{active: navActive('receiving')}" @click="orderChange(30)">{{ $t('mobile.actions.orderReceiving') }}</div>
				<div class="item" :class="{active: navActive('evaluate')}" @click="orderChange(40)">{{ $t('mobile.actions.orderEvaluate') }}</div>
			</div>
			<div style="height: 1.408rem;"></div>
			<div class="mhome-list">
				<div class="item" v-for="(item,index) in orderList" :key="index">
					<div class="item-head">
						<router-link :to="{ name: 'mStoreInfo', params: { id: item.store_id }}" class="name">{{ item.store_name }}<i class="el-icon-arrow-right"></i></router-link>
						<div class="type">{{ item.status | statusFilter($t('mobile.filters.orderStatus')) }}</div>
					</div>
					<router-link :to="{ name: 'mOrderInfo', params: { id: item.order_id }}" class="item-goods">
						<div class="cell" v-for="(goods,index2) in item.orderGoods" :key="index2">
							<img class="cell-pic" :src="goods.goods_image" alt="">
							<div class="cell-main">
								<div class="name">{{goods.goods_name}}</div>
								<div class="desc">{{goods.specification}}</div>
							</div>
							<div class="cell-box">
								<div class="price">￥<span>{{goods.price}}</span></div>
								<div class="number">x{{goods.quantity}}</div>
								<!-- <div class="desc" v-if="goods.refund_status > 0">{{ goods.refund_status | statusFilter($t('mobile.filters.refundStatus'))}}</div> -->
							</div>
						</div>
					</router-link>
					<div class="item-bottom">
						<div class="total">{{$t('mobile.order.actualPay')}}￥<span>{{item.order_amount}}</span></div>
						<div class="actions">
							<template v-if="item.status === 11">
								<div class="btn" @click="handleCancel(item.order_sn)">{{$t('mobile.actions.cancelOrder')}}</div>
								<div class="btn btn-red" @click="handlePayment(item)">{{$t('mobile.actions.immedPay')}}</div>
							</template>
							<template v-if="item.status > 20">
								<div class="btn" v-if="item.delivery_info.length > 1" @click="changeLogistics(item.delivery_info)">{{$t('mobile.actions.viewLogistics')}}</div>
								<router-link class="btn" v-else :to="{ name: 'mOrderLogistic',params: {id: item.order_id,code:item.delivery_info[0]?item.delivery_info[0].delivery_dist_id:null}}">{{$t('mobile.actions.viewLogistics')}}</router-link>
							</template>
							<div class="btn btn-red" v-if="item.status === 30" @click="handleReceipt(item.order_sn)">{{$t('mobile.actions.confirmReceipt')}}</div>
							<router-link class="btn btn-red" v-if="item.status === 40 && item.evaluation_status !== 1" :to="{ name: 'mOrderEvaluate', params: { id: item.order_id }}">{{$t('mobile.actions.immedEvaluation')}}</router-link>
							<div class="btn" v-if="item.status === 0" @click="handleDelete(item.order_sn)">{{$t('mobile.actions.deleteOrder')}}</div>
						</div>
					</div>
				</div>
				<el-empty v-if="!orderList.length>0" :description="$t('mobile.empty.nodata')"></el-empty>
				
			</div>
			
			<!-- 弹窗-支付方式 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.payment}">
				<div class="mpop-bg" @click="handlePopClose('payment')"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('payment')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.payType')}}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popPaymentCheck">
							<el-radio v-for="(item,index) in popPayment" :key="index" :label="JSON.stringify(item)">{{item.payment_name}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleOrderPayment">{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
			
			<!-- <div class="mpop" :class="{'mpop-show':isPopShow.alipay}">
				<div class="mpop-bg"></div>
				<div class="mpop-body">
					<div class="mpop-close left-close" @click="handleAlipayClose"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{$t('mobile.order.fictitious')}}</div>
					<div class="mpop-alipay">
						<div class="mpop-price">￥<span>{{ payOrder?payOrder.order_amount:'0.00' }}</span></div>
						<ul class="mpop-paytype">
							<li>
								<div class="item-icon"><svg-icon icon-class="money_bag_fill"></svg-icon></div>
								<div class="item-block">
									<div class="name">{{ $t('mobile.order.alipayText') }}</div>
									<div class="text">{{ $t('mobile.order.alipayDesc') }}</div>
								</div>
								<div class="item-next"><i class="el-icon-arrow-right"></i></div>
							</li>
						</ul>
					</div>
					<div class="mpop-bottom">
						<div class="btn blue-btn" @click="handleOrderAlipay">{{ $t('mobile.actions.confirmTrade') }}</div>
					</div>
				</div>
			</div>
			<div class="mpop" :class="{'mpop-show':isPopShow.cipher}">
				<div class="mpop-bg"></div>
				<div class="mpop-cipher">
					<div class="mpop-close left-close" @click="handlePopClose('cipher')"><i class="el-icon-close"></i></div>
					<div class="cipher-head">{{ $t('mobile.order.alipayTip') }}</div>
					<div class="cipher-main">
						<div class="cipher-input">
							<el-input v-model="payCipher" maxlength="6" type="tel" ref="paycipher" @input="changeCipher"></el-input>
						</div>
						<div class="cipher-box" @click="handleInput">
							<div class="item" :class="{ active: payCipher.length > 0 }"></div>
							<div class="item" :class="{ active: payCipher.length > 1 }"></div>
							<div class="item" :class="{ active: payCipher.length > 2 }"></div>
							<div class="item" :class="{ active: payCipher.length > 3 }"></div>
							<div class="item" :class="{ active: payCipher.length > 4 }"></div>
							<div class="item" :class="{ active: payCipher.length > 5 }"></div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 弹窗-支付方式 end -->
			<!-- 弹窗-物流 start -->
			<div class="mpop" :class="{'mpop-show':isPopShow.logistic}">
				<div class="mpop-bg"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose('logistic')"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{ $t('mobile.order.logisticPop') }}</div>
					<div class="mpop-group">
						<el-radio-group v-model="popLogisticCheck">
							<el-radio v-for="(item,index) in popLogistic" :key="index" :label="JSON.stringify(item)">{{item.delivery_name?item.logistic_code+'【'+item.delivery_name+'】':item.logistic_code}}</el-radio>
						</el-radio-group>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleLogistic">{{ $t('mobile.actions.confirm') }}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-物流 end -->
		</div>
	</div>
</template>

<script>
	import { getOrderList, cancelOrder, deleteOrder, confirmReceipt } from '@/api/mobile/order'
	export default {
		filters: {
			statusFilter(status, option) {
				return option[status]
			}
		},
		data() {
			return {
				orderList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
				pageflag: true,
				isPopShow: {
					payment: false,
					alipay: false,
					cipher: false,
					logistic: false
				},
				popPayment: [],
				popPaymentCheck: '',
				payOrder: null,
				payCipher: '',
				isScroll: false,
				popLogistic: [],
				popLogisticCheck: ''
			}
		},
		created() {
			this.getOrderList()
		},
		methods: {
			navActive(value) {
				const type = this.$route.params.type || 'all'
				return type === value
			},
			getOrderList() {
				let loading = this.$loading()
				const params = {
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				switch(this.$route.params.type) {
					case 'payment':
					params.status = 11
					break;
					case 'delivery':
					params.status = 20
					break;
					case 'receiving':
					params.status = 30
					break;
					case 'evaluate':
					params.status = 40
					break;
				}
				getOrderList(params).then(response => {
					loading.close()
					if (response.data.list.length > 0) {
						this.orderList = this.orderList.concat(response.data.list);
					} else {
						this.pageflag = false;
					}
					this.pageTotal = response.data.count
				})
			},
			handleScroll() {
				if(this.isScroll) {
					this.pageCurr++;
					if (this.pageflag) {
						this.getOrderList()
					}
				} else {
					this.isScroll = true
				}
			},
			orderChange(status) {
				let type = 'all'
				switch(status) {
					case 11:
					type = 'payment'
					break;
					case 20:
					type = 'delivery'
					break;
					case 30:
					type = 'receiving'
					break;
					case 40:
					type = 'evaluate'
					break;
					default: type = ''
				}
				this.$route.params.type = type
				this.pageCurr = 1
				this.pageflag = true
				this.orderList = []
				this.getOrderList()
			},
			handlePayment(order) {
				this.payOrder = Object.assign({}, order)
				this.getPaymentType(order.payments)
				this.$nextTick(() => {
					this.isPopShow.payment = true
				})
			},
			getPaymentType(data) {
				this.popPayment = Object.assign([], data)
				var payCheck = ''
				this.popPayment.forEach(item => {
					if(item.payment_code === 'alipay') {
						payCheck = item
					}
				})
				if(!payCheck) {
					payCheck = this.popPayment[0]
				}
				this.popPaymentCheck = JSON.stringify(payCheck)
				console.log(this.popPaymentCheck)
			},
			handlePopClose(type){
				if(type === 'payment') {
					this.$confirm(this.$t('mobile.confirm.waiverPay'),'',{
						confirmButtonText: this.$t('mobile.actions.continuePay'),
						cancelButtonText: this.$t('mobile.actions.waiver'),
						customClass: 'wap-message-confirm',
						showClose: false,
						closeOnClickModal: false
					}).then(() => { }).catch(() => {
						this.isPopShow[type] = false
					})
				} else {
					this.isPopShow[type] = false
				}
			},
			async handleOrderPayment() {
				this.isPopShow.payment = false;
				const paymentOption = JSON.parse(this.popPaymentCheck)
				const { data } = await this.$store.dispatch('payment/alipay', {order_sn: this.payOrder.order_sn,payment_code: paymentOption.payment_code })
				if(paymentOption.payment_code === 'alipay') { // 支付宝支付
					const div = document.createElement('div')
					div.innerHTML = data.data
					document.body.appendChild(div)
					document.forms['alipaysubmit'].submit()
					document.body.removeChild(div)
				} else {
					this.$message({
						message: this.$t('mobile.successMsg.payment'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.$router.push({ name: "mOrderMsg", params: { id: this.payOrder.order_id }})
				}
			},
			// handleAlipayClose() {
			// 	this.$confirm(this.$t('mobile.confirm.waiverPay'),'',{
			// 		confirmButtonText: this.$t('mobile.actions.continuePay'),
			// 		cancelButtonText: this.$t('mobile.actions.waiver'),
			// 		customClass: 'wap-message-confirm',
			// 		showClose: false,
			// 		closeOnClickModal: false
			// 	}).then(() => {
			// 	}).catch(() => {
			// 		this.$router.push({name: "mOrderInfo", params: {id:this.payOrder.order_id}});
			// 	})
			// },
			// handleOrderAlipay() {
			// 	this.isPopShow.cipher = true;
			// 	this.$nextTick(() => {
			// 		this.$refs.paycipher.focus()
			// 	})
			// },
			// changeCipher(value) {
			// 	if (value.length > 5) {
			// 		const paymentOption = JSON.parse(this.popPaymentCheck)
			// 		paymentOrder({order_sn: this.payOrder.order_sn,payment_code: paymentOption.payment_code }).then(() => {
			// 			this.$message({
			// 				message: this.$t('mobile.successMsg.payment'),
			// 				customClass: 'wap-message',
			// 				type: 'success'
			// 			});
			// 			this.$router.push({name: "mOrderMsg"});
			// 		})
			// 	}
			// },
			// handleInput() {
			// 	this.$refs.paycipher.focus()
			// },
			refreshOrderList() {
				this.pageCurr = 1
				this.pageflag = true
				this.orderList = []
				this.getOrderList()
			},
			handleCancel(number) {
				this.$confirm(this.$t('mobile.confirm.cancelOrder'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					cancelOrder({ order_sn: number }).then(() => {
						this.refreshOrderList()
					})
				}).catch(() => { })
			},
			handleDelete(number) {
				this.$confirm(this.$t('mobile.confirm.deleteOrder'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					deleteOrder({ order_sn: number }).then(() => {
						this.refreshOrderList()
					})
				}).catch(() => { })
			},
			handleReceipt(number) {
				this.$confirm(this.$t('mobile.confirm.receiptText'),'',{
					confirmButtonText: this.$t('mobile.actions.confirm'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					customClass: 'wap-message-confirm',
					showClose: false,
					closeOnClickModal: false
				}).then(() => {
					confirmReceipt({ order_sn: number }).then(() => {
						this.refreshOrderList()
					})
				}).catch(() => { })
			},
			changeLogistics(data) {
				this.popLogisticCheck = ''
				this.popLogistic = JSON.parse(JSON.stringify(data))
				this.isPopShow.logistic = true;
			},
			handleLogistic() {
				if(this.popLogisticCheck) {
					const checkData = JSON.parse(this.popLogisticCheck)
					this.$router.push({ name: 'mOrderLogistic',params: {id: checkData.order_id,code:checkData.delivery_dist_id}})
				} else {
					this.$message({
						message: this.$t('mobile.errorMgs.selectLogistic'),
						customClass: 'wap-message',
						type: 'error'
					});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mhome-head {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		position: absolute;
		background-color: #ffffff;
		.item {
			flex: 1;
			height: 1.408rem;
			line-height: 1.408rem;
			text-align: center;
			font-size: 0.416rem;
			&.active {
				color: #ff5000;
				font-weight: bold;
				font-size: 0.448rem;
			}
		}
	}
	.mhome-list {
		padding: 0.32rem;
		.item {
			margin-bottom: 0.32rem;
			border-radius: 0.256rem;
			background-color: #ffffff;
			.item-head {
				display: flex;
				padding: 0.384rem;
				line-height: 0.544rem;
				justify-content: space-between;
				.name {
					color: #333333;
					font-size: 0.448rem;
					font-weight: bold;
					i {
						color: #999999;
					}
				}
				.type {
					color: #ff5000;
					font-size: 0.416rem;
				}
			}
			.item-goods {
				display: block;
				padding: 0 0.384rem;
				.cell {
					display: flex;
					margin-bottom: 0.32rem;
					.cell-pic {
						flex-shrink: 0;
						width: 2.176rem;
						height: 2.176rem;
						border-radius: 0.32rem;
						object-fit: cover;
					}
					.cell-main {
						flex: 1;
						width: 0;
						margin: 0 0.32rem;
						.name {
							color: #333333;
							font-size: 0.416rem;
							height: 0.768rem;
							line-height: 0.768rem;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-weight: 600;
						}
						.desc {
							max-height: 1.088rem;
							line-height: 0.544rem;
							color: #999999;
							font-size: 0.416rem;
							display: -webkit-box;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							white-space: normal;
						}
					}
					.cell-box {
						flex-shrink: 0;
						text-align: right;
						.price {
							height: 0.768rem;
							line-height: 0.768rem;
							color: #333333;
							font-weight: 600;
							font-size: 0.384rem;
							span {
								font-size: 0.448rem;
							}
						}
						.number {
							height: 0.544rem;
							line-height: 0.544rem;
							color: #999999;
							font-size: 0.384rem;
						}
						.desc {
							color: #E6A23C;
							height: 0.544rem;
							line-height: 0.544rem;
							font-size: 0.384rem;
							margin-top: 0.128rem;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.item-bottom {
				padding: 0.256rem 0.384rem;
				.total {
					text-align: right;
					font-size: 0.384rem;
					font-weight: 600;
					line-height: 0.64rem;
					margin-bottom: 0.256rem;
					span {
						font-size: 0.416rem;
					}
				}
				.actions {
					display: flex;
					justify-content: flex-end;
					.btn {
						color: #333333;
						font-size: 0.416rem;
						flex-shrink: 0;
						height: 0.544rem;
						line-height: 0.544rem;
						border: 1px solid #ddd;
						padding: 0.192rem 0.32rem;
						border-radius: 0.64rem;
						margin-left: 0.32rem;
						display: inline-block;
					}
					.btn-red {
						color: #ff5000;
						border-color: #ff5000;
					}
				}
			}
		}
	}
	
	.mpop-group {
		padding: 0.256rem 0.384rem;
		::v-deep .el-radio-group {
			display: block;
			.el-radio {
				display: block;
				margin-right: 0;
				padding: 8px 0;
				.el-radio__input {
					.el-radio__inner {
						width: 0.512rem;
						height: 0.512rem;
						border-radius: 50%;
						&:after {
							top: 0.064rem;
							left: 0.16rem;
							width: 0.096rem;
							height: 0.224rem;
							border-radius: 0;
							background-color: transparent;
							position: absolute;
							transform: rotate(45deg) scaleY(0);
							border-right: 0.032rem solid #FFFFFF;
							border-bottom: 0.032rem solid #FFFFFF;
						}
					}
					&.is-checked {
						.el-radio__inner {
							background-color: #f25528;
							border-color: #f25528;
							&:after {
								transform: rotate(45deg) scaleY(1);
							};
						}
					}
				}
				.el-radio__label {
					color: #333333;
					font-size: 0.416rem;
					line-height: 0.512rem;
					vertical-align: middle;
					span {
						color: #999999;
						margin-left: 0.192rem;
						font-size: 0.384rem;
					}
				}
			}
		}
	}
	.mpop-alipay {
		height: 8rem;
		.mpop-price {
			text-align: center;
			font-size: 0.576rem;
			padding: 0.32rem 0;
			margin-bottom: 0.32rem;
			span {
				font-size: 0.96rem;
			}
		}
		.mpop-paytype {
			margin: 0 0.32rem;
			border-top: 1px solid #E5E5E5;
			li {
				display: flex;
				padding: 0.256rem 0;
				justify-content: space-between;
				border-bottom: 1px solid #E5E5E5;
				.item-icon {
					color: #ff0005;
					font-size: 0.512rem;
				}
				.item-block {
					flex: 1;
					margin-left: 0.32rem;
					.name {
						font-size: 0.416rem;
						line-height: 0.544rem;
					}
					.text {
						color: #999999;
						font-size: 0.352rem;
						line-height: 0.544rem;
						margin-top: 0.128rem;
					}
				}
				.item-next {
					line-height: 0.544rem;
					color: #999999;
					font-size: 0.448rem;
				}
			}
		}
	}
	
	.mpop-bottom {
		.blue-btn {
			border-radius: 0.32rem;
			background: $--uucolor-primary;
		}
	}
	.mpop-cipher {
		top: 50%;
		left: 10%;
		width: 80%;
		padding: 0.64rem;
		box-sizing: border-box;
		border-radius: 0.32rem;
		background-color: #FFFFFF;
		position: absolute;
		transform: translateY(-50%);
		.cipher-head {
			font-size: 0.48rem;
			line-height: 0.64rem;
			font-weight: 600;
			text-align: center;
			padding-top: 0.96rem;
		}
		.cipher-main {
			padding: 0.64rem 0;
			.cipher-input {
				height: 0;
				overflow: hidden;
			}
			.cipher-box {
				display: flex;
				border-radius: 0.16rem;
				border: 1px solid #e5e5e5;
				.item {
					flex: 1;
					width: 0;
					height: 1.28rem;
					line-height: 1.28rem;
					font-size: 0.512rem;
					text-align: center;
					border-left: 1px solid #e5e5e5;
					&:first-child {
						border-left: none;
					}
					&.active {
						display: flex;
						justify-content: center;
						align-items: center;
						&:before {
							content: "";
							width: 0.32rem;
							height: 0.32rem;
							display: block;
							border-radius: 50%;
							background-color: #333333;
						}
					}
				}
			}
		}
	}
</style>